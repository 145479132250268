<template>
    <!-- <v-card class="" tile> -->
    <!-- <v-card-text class="pa-0">
            <v-img class="" src="@/assets/images/avatars/img-xl.svg" height="120" />
        </v-card-text> -->
    <v-container class="pt-8 pb-10">
        <v-row justify="center">
            <v-col cols="11" class="px-0">
                <div class="d-flex justify-space-between flex-wrap">
                    <h1 class="primary--text">
                        Create Service|Product
                    </h1>
                </div>
                <hr class="hr-l mb-4 mt-2">
            </v-col>
        </v-row>
        <v-stepper v-model="e1" elevation="0">
            <v-stepper-header class="px-3">
                <v-stepper-step :complete="this.e1 > 1" step="1">
                    <span class="text-subtitle-1">{{ this.servicetype }} details</span>
                </v-stepper-step>
                <v-spacer></v-spacer>
                <v-stepper-step :complete="this.e1 > 2" step="2">
                    <span class="text-subtitle-1">Attach photos to {{ servicetype }}</span>
                </v-stepper-step>
            </v-stepper-header>
            <v-stepper-items>
                <v-stepper-content step="1">
                    <v-card-text class="text-h5 font-weight-bold mb-6">Complete {{ this.servicetype }} details
                    </v-card-text>
                    <v-card-text class="mt-6">
                        <v-form class="">
                            <v-row class="py-0">
                                <v-col cols="12" md="6" class="py-0">
                                    <v-radio-group v-model="servicetype" hide-details mandatory row class="mb-0">
                                        <span class="mr-4">Type: </span>
                                        <v-radio v-for="(item, i) in servicetypeitem" :key="i" :value="item"
                                            color="secondary" hide-details class="pb-0 mb-0">
                                            <template v-slot:label>
                                                <div>
                                                    <span class="seconday--text">{{ item }}</span>
                                                </div>
                                            </template>
                                        </v-radio>
                                    </v-radio-group>
                                </v-col>

                                <v-col cols="12" md="6" class="">
                                    <v-select v-model="servicedomain" dense outlined
                                        :label="this.servicetype + ' domain'" :items="domain_list"
                                        :hide-details="servicedomainErrors.isvalid"
                                        :error-messages="servicedomainErrors.value" @input="$v.servicedomain.$touch()"
                                        @blur="$v.servicedomain.$touch()">
                                    </v-select>
                                </v-col>
                                <v-col cols="12" class="">
                                    <v-text-field v-model="servicetitle" :label="this.servicetype + ' title'"
                                        type="text" outlined dense :placeholder="this.servicetype + ' title'"
                                        :hide-details="servicetitleErrors.isvalid"
                                        :error-messages="servicetitleErrors.value" @input="$v.servicetitle.$touch()"
                                        @blur="$v.servicetitle.$touch()">
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" class="">
                                    <vue-editor v-model="servicecontent" outlined dense class="text-left mb-3"
                                        label="description" placeholder="Describe your product or service here">
                                    </vue-editor>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                    <v-card-actions class="mt-4 ml-0">
                        <v-btn type="reset" color="error" outlined class="mx-2">
                            Reset
                        </v-btn>
                        <v-btn color="secondary" @click="createNewServiceProduct()" :loading="loading">
                            Next
                        </v-btn>
                    </v-card-actions>
                </v-stepper-content>
                <v-stepper-content step="2">
                    <v-card-text>
                        <upload :saveimage.sync="initSave" :localsave="false" @imagesaved="initSaveImage"
                            @imgselected="checkSelectedImage">
                        </upload>
                    </v-card-text>

                    <v-card-actions class="mx-4">
                        <v-btn text outlined @click="exitAttachImage" color="primary" v-if="imgstate === false">
                            <v-icon small class="mr-2">{{ icons.mdiArrowLeft }}</v-icon>
                            <span>Cancel</span>
                        </v-btn>

                        <v-btn text outlined @click="saveAttachImage" color="primary" v-if="imgstate === true">
                            <v-icon small class="mr-2">{{ icons.mdiFile }}</v-icon>
                            <span>Save only</span>
                        </v-btn>

                        <v-btn @click="saveAndShare" class="primary">
                            <v-icon small class="mr-2">{{ icons.mdiShareAllOutline }}</v-icon>
                            <span>Publish</span>
                        </v-btn>
                    </v-card-actions>
                </v-stepper-content>
            </v-stepper-items>
        </v-stepper>

    </v-container>
    <!-- </v-card> -->
</template>

<script>
import { ref } from '@vue/composition-api'
import { VueEditor } from "vue2-editor";
import { validationMixin } from 'vuelidate'
import { required, minLength, maxLength, email } from 'vuelidate/lib/validators'

import { mdiArrowLeft, mdiFile, mdiShareAllOutline } from '@mdi/js'

import { Drequest } from '@/plugins/Drequest'
import Upload from './Upload.vue'

export default {

    mixins: [validationMixin],
    validations: {
        servicedomain: { required },
        servicetitle: { required },
    },

    data() {
        return {
            e1: 1,
            loading: false,
            initSave: false,
            imgstate: false,
            saveOnly: true
        }
    },
    components: {
        VueEditor,
        Upload,
    },
    computed: {
        servicetitleErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.servicetitle.$dirty) return errors
            !this.$v.servicetitle.required && errors.value.push(this.servicetype + ' title required.') && (errors.isvalid = false)
            return errors
        },
        servicedomainErrors() {
            const errors = { isvalid: true, value: [] }
            if (!this.$v.servicedomain.$dirty) return errors
            !this.$v.servicedomain.required && errors.value.push(this.servicetype + ' domain required.') && (errors.isvalid = false)
            return errors
        },
    },
    methods: {
        exitAttachImage() {
            this.$router.push({ name: 'company.service.list' })
        },
        saveAttachImage() {
            this.initSave = true
            this.saveOnly = true
        },
        saveAndShare() {
            this.saveOnly = false
            if (this.imgstate === true) { this.initSave = true }
            else {
                this.publish()
            }
        },
        publish() {
            Drequest.api(`service.publish?id=${this.$store.getters.getService.id}`)
                .post((response) => {
                    if (response.success === true) {
                        this.$fire({
                            type: "success",
                            text: `${this.servicesDatas.name} succefull published`,
                            timer: 3000
                        })
                    } else {
                        this.$fire({
                            type: "error",
                            text: "Retry later..!",
                            timer: 3000
                        })
                    }
                })
                .catch((err) => {
                    console.log("err")
                })
        },
        checkSelectedImage(state) {
            this.imgstate = state
        },

        initSaveImage(save_state) {
            // if (save_state === true) {
            if (this.saveOnly === false) {
                this.publish()
            }
            else {
                this.$fire({
                    type: "success",
                    text: "Succesfull saved",
                    timer: 3000
                })
            }
            this.exitAttachImage()
            // }
        },
        checkValidationService() {
            if (this.servicetitleErrors.isvalid && this.servicedomainErrors.isvalid) {
                return true
            }
            else {
                return false
            }
        },
        createNewServiceProduct() {
            var bodyFormData = {
                "service": {
                    "name": this.servicetype,
                    "title": this.servicetitle,
                    "status": '0',
                    "description": this.servicecontent,
                    "domain": this.servicedomain,
                    "enterprise.id": this.$store.getters.getCompany.id
                }
            }

            this.$v.$touch()

            if (this.checkValidationService()) {
                this.loading = true;
                Drequest.api("service.create")
                    .data(bodyFormData)
                    .raw((response) => {
                        if (response.success) {
                            this.loading = false;
                            this.$store.commit("setService", {
                                id: response.service.id
                            });

                            // this.$fire({
                            //     type: "success",
                            //     text: `New ${this.servicetype} created`,
                            //     timer: 3000
                            // })
                            // // this.goToNextStep()
                            this.e1 = 2
                        }
                        else {
                            this.loading = false;
                            this.$fire({
                                type: "error",
                                text: "Uncount error..!",
                                timer: 3000
                            }).then(r => {
                                // console.log(r.value);
                            });
                        }
                    })
                    .catch((err) => {
                        this.loading = false;
                        this.$fire({
                            type: "error",
                            text: "Error occured, try later",
                            timer: 3000
                        }).then(r => {
                            console.log('Error during connexion to the server..!');
                        });
                    })
            }
        },
    },
    beforeMount() {
        if (this.$route.name.split(".")[2] === 'edit') {
            Drequest.api(`lazyloading.service?dfilters=on&user_id:eq=${this.$route.params.value}&enterprise_id:eq=${this.$store.getters.getCompany.id}`).get((response) => {
                if (response.success === true) {
                    response.listEntity.forEach((item, index) => {
                        
                    })
                    this.loading = false
                }
            }).catch((err) => {
                // alert('Server error')
                this.loading = false
            });
        }
    },
    setup() {
        const domain_list = [
            'Business Et Entreprenariat',
            'Énergies Renouvelables Et Électricité',
            'Éducation Et Formation',
            'Pétrole Et Gaz',
            'TICs Et Transformation Numérique',
            'Économie, Politique Et Droit',
            'Mines',
            'Agriculture Et Agro-Industrie',
            'Others'
        ]
        const servicetypeitem = [
            'Service',
            'Product'
        ]
        const servicetype = ref('')
        const servicedomain = ref('')
        const servicetitle = ref('')
        const servicecontent = ref('')

        const icons = { mdiArrowLeft, mdiFile, mdiShareAllOutline }
        return {
            icons,
            domain_list, servicetypeitem,
            servicetype,
            servicedomain,
            servicetitle,
            servicecontent,
        }
    },
}
</script>

<style lang="scss" scoped>
.avatar-center {
    top: -50%;
    position: relative;
    margin-left: auto;
    margin-right: auto;
}

.v-card--reveal {
    bottom: 0;
    opacity: 1 !important;
    position: absolute;
    width: 100%;
}

.vvv {
    border: solid 1px #000 !important;
}
</style>
